import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
// import { useWindowScroll } from '@mantine/hooks';

const Button = dynamic(() => import('@components/elements/button'));

const Header = () => {
  const [isScroll, setScroll] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const router = useRouter();
  // const [scroll, scrollTo] = useWindowScroll();

  useEffect(() => {
    window.addEventListener('scroll', updateColorHeader, { capture: true });
    window.addEventListener('resize', resizeWidthBody, { capture: true });

    return () => {
      window.removeEventListener('scroll', updateColorHeader, { capture: true });
      window.removeEventListener('resize', resizeWidthBody, { capture: true });
    };
  });

  const updateColorHeader = () => {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const resizeWidthBody = () => {
    if (document.body.offsetWidth >= 768 || document.documentElement.offsetWidth > 768) {
      setShowModal(false);
    }
  };

  const menuModal = () => {
    return showModal ? (
      <>
        <div className="top-0 left-0 w-full h-screen z-[1000] bg-white dark:bg-gray-800 overflow-x-hidden overflow-y-auto fixed inset-0 transition-all duration-500 block md:hidden">
          <div className="flex items-center justify-between shadow-base p-[20px]">
            <a
              className="w-[120px] md:w-[200px] h-auto flex items-center"
              onClick={() => router.push('/')}
              data-unq="landingpage-button-home">
              <Image
                src="/images/brands/chakra.webp"
                alt="Chakra Rewards"
                width={250}
                height={50}
              />
            </a>
            <i className="cursor-pointer" onClick={() => setShowModal(false)}>
              close
            </i>
          </div>
          <div className="flex flex-col items-start justify-center w-full relative space-y-4 p-[20px]">
            <a
              className="text-[14px] font-medium cursor-pointer hover:text-blue-600"
              onClick={() => router.push('/')}>
              Home
            </a>
            <a
              className="text-[14px] font-medium cursor-pointer hover:text-blue-600"
              onClick={() => router.push('/contact-us')}>
              Contact
            </a>
            <Button type="button" isBlock color="primary" onClick={() => router.push('/register')}>
              Create Account
            </Button>
            <Button
              type="button"
              color="primary"
              isBlock
              isOutlined
              onClick={() => router.push('/login')}>
              Sign In
            </Button>
          </div>
        </div>
      </>
    ) : null;
  };

  return (
    <header
      className="fixed top-0 left-0 w-full flex justify-center p-[15px] md:p-[30px] z-[1000] transition-all"
      style={{ backgroundColor: isScroll ? 'bg-white' : 'transparent' }}>
      <div className="w-full max-w-[1600px] flex justify-between items-center">
        <div className="flex items-center">
          <a
            className="w-[120px] md:w-[200px] h-auto flex items-center"
            onClick={() => router.push('/')}
            data-unq="landingpage-button-home">
            <Image src="/images/brands/chakra.webp" alt="Chakra Rewards" width={250} height={50} />
          </a>
          <div className="hidden md:flex ml-[20px]">
            <a
              className="ml-[30px] whitespace-nowrap text-gray-900 dark:text-white cursor-pointer"
              onClick={() => router.push('/contact-us')}
              data-unq="landingpage-button-contact">
              Contact
            </a>
          </div>
        </div>

        <div className="flex items-center">
          <div className="hidden md:block whitespace-nowrap">
            <a
              className="text-gray-700 dark:text-blue-400 cursor-pointer"
              data-unq="landingpage-button-login"
              onClick={() => router.push('/login')}>
              Sign In
            </a>
          </div>

          <div className="hidden md:block ml-[60px]">
            <Button
              type="button"
              className="button button--base button--brand w-auto h-[50px] md:h-[60px]"
              onClick={() => router.push('/register')}
              data-unq="landingpage-button-register">
              <span className="text-sm">Create Account</span>
            </Button>
          </div>
          <a
            className="text-gray-900 dark:text-white cursor-pointer md:hidden flex items-center"
            data-unq="header-button-menu"
            onClick={() => setShowModal(true)}>
            <i className="text-[32px]">menu</i>
          </a>
        </div>
      </div>

      {menuModal()}
    </header>
  );
};

export default Header;
